import { useState, useLayoutEffect, useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Path from '../components/Link/Path';
import { useLocation, useNavigate } from 'react-router';
import { UserOutlined } from '../components/svg';
import { Input, SelectInput } from '../components/Input';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { SelectChangeEvent } from '@mui/material/Select';
import validator from 'validator';
import { roleSelector } from '../redux/selectors/user';
import ColorType from '../components/Theme/ColorType';
import { RoleOption } from '../const/types';
import {
  buildingManagerRoles,
  conciergeRoles,
  extraAdminRoles,
  managerRoles,
} from '../const/arrays';
import { updateUsers } from '../redux/actions/usersAction';
import { buildingsFullListSelector } from '../redux/selectors/building';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

type ErrorProp = {
  userName?: string;
  userEmail?: string;
  role?: string;
  status?: string;
};

const Floors = [
  { value: '0', label: 'Choose a number of floors', placeholder: true },
  ...Array.from({ length: 10 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })),
];

const Statuses = [
  { value: '1', label: 'Active' },
  { value: '0', label: 'Inactive' },
];

const UserDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userRole = useAppSelector(roleSelector);
  const buildingsId = useAppSelector(buildingsFullListSelector);
  const user: any = location?.state?.user;
  const dispatch = useAppDispatch();
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('Choose a role');
  const [userEmail, setUserEmail] = useState('');
  const [status, setStatus] = useState('1');
  const [errors, setErrors] = useState<ErrorProp>({});
  const [building, setBuilding] = useState<any>([]);
  const [openSelect, setOpen] = useState(false);
  const isUpdated =
    user.name === userName &&
    user.email === userEmail &&
    user.role === parseInt(status, 10) &&
    user.status === parseInt(status, 10);
  const normalUser =
    user.role !== 'admin' &&
    user.role !== 'super-admin' &&
    user.role !== 'manager';

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  const handleDisplayRoles = (): RoleOption[] => {
    switch (userRole) {
      case 'super-admin':
      case 'admin':
        return extraAdminRoles;
      case 'manager':
        return managerRoles;
      case 'building-manager':
        return buildingManagerRoles;
      case 'consierge':
        return conciergeRoles;
      default:
        return conciergeRoles;
    }
  };

  const Roles = handleDisplayRoles();

  useLayoutEffect(() => {
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      setRole(user.role);
      setUserEmail(user.email);
      setStatus(Statuses.find((s) => s.label === user.status)?.value || '1');
    }
    return () => {
      setErrors({});
      setUserName('');
      setUserEmail('');
      setRole(Floors[0].value);
      setStatus(Statuses[0].value);
    };
  }, [user]);

  const handleChangeName = (text: string) => {
    setUserName(text);
  };

  const handleChangeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleOpenSelect = () => {
    setOpen(true);
  };

  const handleCloseSelect = () => {
    setOpen(false);
  };

  const handleChangeBuildings = (event: any) => {
    const {
      target: { value },
    } = event;
    const isAllSelected = value.find((e: number) => e === 0);
    if (isAllSelected === 0) {
      const newValues = [...buildingsId];
      const allIds = newValues.filter((e) => e.value !== 0).map((e) => e.value);
      setBuilding(allIds);
      handleCloseSelect();
    } else {
      setBuilding(value);
    }
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (validator.isEmpty(userName)) {
      errors.userName = 'A valid user name is required';
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const buildings = normalUser
      ? building
      : user.buildings.map((b: any) => b.id);

    const data = {
      id: user.id,
      name: userName,
      email: userEmail,
      role: role,
      buildings,
      status,
    };
    dispatch(updateUsers({ data, userId, navigate }));
  };

  return (
    <Container>
      <GridHeaderContainer container>
        <Grid xs={12} sm={5} item>
          <Path />
          <TitleDivContainer>
            <AvatarContainer>
              <UserOutlined
                height={'72px'}
                width={'72px'}
                stroke={ColorType.infoDark}
              />
            </AvatarContainer>
            <TextContainer>
              <StyledBoldSpan>{userName}</StyledBoldSpan>
              <StyledSpan>{userEmail}</StyledSpan>
            </TextContainer>
          </TitleDivContainer>
        </Grid>
        <Grid
          xs={12}
          sm={5}
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ActionButton
            buttonLabel="Update"
            color={'primary'}
            OnClick={handleSendData}
            disabled={isUpdated}
            style={{
              borderRadius: 6,
            }}
          />
        </Grid>
      </GridHeaderContainer>
      <GridContentContainer container>
        <Grid xs={12} sm={5} item>
          <Input
            label={t('user-name-label')}
            placeholder={t('user-name-placeholder')}
            value={userName}
            hasBorder
            OnChange={handleChangeName}
            error={!!errors?.userName}
            errorMessage={errors?.userName}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <SelectInput
            label={t('user-role-label')}
            values={Roles}
            value={role}
            OnChange={handleChangeRole}
            error={!!errors?.role}
            errorMessage={errors?.role}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <Input
            label={t('user-email-label')}
            placeholder={t('user-email-placeholder')}
            value={userEmail}
            hasBorder
            disabled
            OnChange={() => null}
            error={!!errors?.userEmail}
            errorMessage={errors?.userEmail}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <SelectInput
            label={t('user-status-label')}
            placeholder={t('user-status-placeholder')}
            values={Statuses}
            value={status}
            OnChange={handleChangeStatus}
            error={!!errors?.status}
            errorMessage={errors?.status}
          />
        </Grid>
        {normalUser && (
          <Grid xs={12} sm={5} item>
            <SelectInput
              label={t('user-building-label') as string}
              values={buildingsId}
              value={building}
              OnChange={handleChangeBuildings}
              open={openSelect}
              onOpen={handleOpenSelect}
              onClose={handleCloseSelect}
              multiple
            />
          </Grid>
        )}
      </GridContentContainer>
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  margin-right: 16px;
  background-color: ${ColorType.infoBackground};
  border-radius: 50px;
  padding: 16px;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledBoldSpan = styled('span')`
  font-size: 24px;
  font-weight: 600;
  font-family: Rubik;
`;

const StyledSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const GridHeaderContainer = styled(Grid)(() => ({
  gap: 15,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

const GridContentContainer = styled(Grid)(() => ({
  gap: 15,
}));

export default UserDetails;
