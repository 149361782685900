import { useEffect, useState } from 'react';
import Path from '../components/Link/Path';
import { styled } from '@mui/material/styles';
import GatewayTable from '../components/Tables/GatewayTable';
import { ActionButton } from '../components/Button';
import { Plus } from '../components/svg';
import ColorType from '../components/Theme/ColorType';
import { Theme } from '@mui/system';
import { Grid } from '@mui/material';
import { Tab } from '../components/Tabs';
import ControllerTable from '../components/Tables/ControllerTable';
import SensorTable from '../components/Tables/SensorTable';
import ImportModal from '../components/Modal/ImportModal';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import {
  getDevicesByType,
  getGateways,
  getMessages,
} from '../redux/actions/deviceAction';
import { CONTROLLER, SENSOR } from '../const/values';
import { gatewaysSelector } from '../redux/selectors/device';
import MessagesTable from '../components/Tables/MessagesTable';
import { buildingSelector } from '../redux/selectors/building';
import {
  isAdminSelector,
  isManagerSelector,
  nameSelector,
  roleSelector,
} from '../redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';
import { companyIdSelector } from '../redux/selectors/company';

const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === 'dark' ? '#000' : '#383838'
  }`,
});

const ComponentsManagement = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const gateways = useAppSelector(gatewaysSelector);
  const companyId = useAppSelector(companyIdSelector);
  const building = useAppSelector(buildingSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  const userName = useAppSelector(nameSelector);
  const role = useAppSelector(roleSelector);
  const isSuper = isAdmin || isManager;

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    dispatch(getGateways(!isSuper ? building?.id : ''));
    dispatch(
      getDevicesByType({
        componentType: CONTROLLER,
        page: 1,
        companyId,
        buildingId: building?.id,
        keyword: '',
      })
    );
    dispatch(
      getDevicesByType({
        componentType: SENSOR,
        page: 1,
        companyId,
        buildingId: building?.id,
        keyword: '',
      })
    );
    isAdmin && dispatch(getMessages({ page: 1 }));
  }, [dispatch]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDisplayRole = (role: string) => {
    if (role === 'super-admin') return t('super-admin-role');
    if (role === 'admin') return t('admin-role');
    if (role === 'manager') return t('manager-role');
    if (role === 'building-manager') return t('building-manager-role');
  };

  return (
    <Container>
      <HeaderContainer container>
        <StyledDiv item>
          <Path />
          <TitleSpan>
            {userName},{' '}
            <DescriptionSpan style={{ fontSize: 24 }}>
              {handleDisplayRole(role)}
            </DescriptionSpan>
          </TitleSpan>
          <DescriptionSpan>{t('devices-title')}</DescriptionSpan>
        </StyledDiv>
        <ButtonContainer item style={{ paddingRight: '24px' }}>
          <ActionButton
            buttonLabel={t('devices-import-button')}
            color={'primary'}
            iconLeft={<PlusIcon />}
            OnClick={handleOpen}
            style={{
              borderRadius: 6,
            }}
          />
        </ButtonContainer>
      </HeaderContainer>
      {gateways && (
        <Tab
          GatewayChildren={<GatewayTable />}
          ControllerChildren={<ControllerTable />}
          SensorsChildren={<SensorTable />}
          MessagesChildren={<MessagesTable />}
        />
      )}
      <ImportModal open={open} handleClose={handleClose} style={style} />
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const ButtonContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled(Plus)(() => ({
  width: 20,
  height: 20,
  marginRight: '6px',
  stroke: ColorType.background,
}));

export default ComponentsManagement;
