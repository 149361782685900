/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { SelectInput, UnifiedInput } from '../Input';
import { Checkbox, Grid, SelectChangeEvent } from '@mui/material';
import { useAppSelector } from '../../utils/redux';
import {
  // buildingFloorsSelector,
  buildingIdSelector,
  buildingsListSelector,
} from '../../redux/selectors/building';
import { CONTROLLER, GATEWAY, SENSOR } from '../../const/values';
import { controllersSelectListSelector } from '../../redux/selectors/device';
import { companyIdSelector } from '../../redux/selectors/company';
import { DeviceUpdateProps } from '../../const/types';
import ColorType from '../Theme/ColorType';
import SelectSearch from '../Input/SelectSearch';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../Button';
import ChannelModal from '../Modal/ChannelModal';

type ErrorProp = {
  deviceEUI?: string;
  applicationKey?: string;
  deviceApplicationEUI?: string;
  devicePin?: string;
  model?: string;
  serialNumber?: string;
  assetNumber?: string;
  ipAddress?: string;
  mac?: string;
  latitude?: string;
  longitude?: string;
  altitude?: string;
  status?: string;
  floor?: string;
  floorArea?: string;
  floorSubArea?: string;
  awsId?: string;
};

const DeviceUpdateCard = ({
  gateway,
  controller,
  sensor,
  handleSendData,
}: DeviceUpdateProps) => {
  const { t } = useTranslation();
  const buildings = useAppSelector(buildingsListSelector);
  const buildingId = useAppSelector(buildingIdSelector);
  const companyId = useAppSelector(companyIdSelector);
  const controllersIds = useAppSelector(controllersSelectListSelector);
  const [gatewayName, setGatewayName] = useState('');
  const [deviceEUI, setDeviceEUI] = useState('');
  const [deviceApplicationEUI, setDeviceApplicationEUI] = useState('');
  const [applicationKey, setApplicationKey] = useState('');
  const [model, setModel] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [assetNumber, setAssetNumber] = useState('');
  const [errors, setErrors] = useState<ErrorProp>({});
  const [floor, setFloor] = useState('0');
  const [floorArea, setFloorArea] = useState('');
  const [floorSubArea, setFloorSubArea] = useState('');
  const [awsId, setAwsId] = useState('');
  const [building, setBuilding] = useState('');
  const [devices, setDevices] = useState<any>([]);
  const [channelA, setChannelA] = useState(false);
  const [channelB, setChannelB] = useState(false);
  const [channels, setChannels] = useState<any[]>();
  const [isSelectChannel, setIsSelectChannel] = useState(false);
  // const floorCount = useAppSelector((state) =>
  //   buildingFloorsSelector(state, parseInt(building, 10))
  // );

  const componentType = !!gateway
    ? GATEWAY
    : !!controller
    ? CONTROLLER
    : SENSOR;

  useLayoutEffect(() => {
    if (gateway) {
      setGatewayName(gateway?.name);
    }
    setFloor((gateway || controller || sensor)?.device_location.floor || '');
    setFloorArea(
      (gateway || controller || sensor)?.device_location?.floor_area || ''
    );
    setFloorSubArea(
      (gateway || controller || sensor)?.device_location?.floor_sub_area || ''
    );
    setAwsId((controller || sensor)?.aws_id || '');
    setDeviceEUI((controller || sensor)?.eui || '');
    setDeviceApplicationEUI((controller || sensor)?.dev_application_eui || '');
    setApplicationKey((controller || sensor)?.application_key || '');
    setModel((controller || sensor)?.model || '');
    setSerialNumber((controller || sensor)?.serial_number || '');
    setAssetNumber((controller || sensor)?.asset_number || '');
    if (sensor) {
      /* @ts-ignore */
      const DeviceList = sensor.controllers.map(
        (device: any) => device.asset_number
      );
      const newDevices = controllersIds.filter((controller) =>
        DeviceList.includes(controller.label)
      );
      setDevices(newDevices);
    }

    if (controller) {
      controller.channels?.map((channel) => {
        if (channel.channel_code === 'A') {
          setChannelA(channel.status === 'open');
        } else if (channel.channel_code === 'B') {
          setChannelB(channel.status === 'open');
        }
      });
    }

    return () => {
      setErrors({});
      setGatewayName('');
      setFloor('0');
      setFloorArea('');
      setFloorSubArea('');
      setAwsId('');
      setDeviceEUI('');
      setDeviceApplicationEUI('');
      setApplicationKey('');
      setModel('');
      setSerialNumber('');
      setAssetNumber('');
    };
  }, [controller, controllersIds, gateway, sensor]);

  useEffect(() => {
    if (gateway) setBuilding(gateway.building.id.toString());
    if (sensor) setBuilding(sensor.building.id.toString());
    if (controller) setBuilding(controller.building.id.toString());
  }, [buildingId, buildings, controller, gateway, sensor]);

  // const Floors = [
  //   { value: '0', label: 'Choose a number of floors', placeholder: true },
  //   ...Array.from({ length: floorCount || 0 }, (_, index) => ({
  //     value: (index + 1).toString(),
  //     label: (index + 1).toString(),
  //   })),
  // ];

  const stateSetters: any = {
    name: setGatewayName,
    deviceEUI: setDeviceEUI,
    deviceApplicationEUI: setDeviceApplicationEUI,
    applicationKey: setApplicationKey,
    model: setModel,
    serialNumber: setSerialNumber,
    assetNumber: setAssetNumber,
    floorArea: setFloorArea,
    floorSubArea: setFloorSubArea,
    awsId: setAwsId,
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const setter = stateSetters[name];
    if (setter) {
      setter(value);
    }
  };

  const handleChangeBuilding = (event: SelectChangeEvent) => {
    setBuilding(event.target.value);
  };

  const handleChangeFloor = (event: SelectChangeEvent) => {
    setFloor(event.target.value);
  };

  const sendData = () => {
    if (gateway) {
      const data = {
        name: gatewayName,
        building_id: building,
        company_id: companyId,
        floor,
        floor_area: floorArea,
        floor_sub_area: floorSubArea,
        status: true,
      };
      return handleSendData(data, componentType);
    }

    let channel = '';

    if (channelA && channelB) {
      channel = 'AB';
    } else if (channelB) {
      channel = 'B';
    } else {
      channel = 'A';
    }

    let deviceIds = [];
    if (devices.length > 0 && devices.length < 2) {
      deviceIds = devices.map((device: any) => `${device.value}_${channel}`);
    }
    if (devices.length > 1) {
      deviceIds = channels?.map((channel: any) => `${channel.device}`) || [];
    }

    const data = {
      asset_number: assetNumber,
      serial_number: serialNumber,
      eui: deviceEUI,
      application_key: applicationKey,
      dev_application_eui: deviceApplicationEUI,
      model,
      building_id: parseInt(building, 10),
      company_id: companyId,
      floor,
      floor_area: floorArea,
      floor_sub_area: floorSubArea,
      aws_id: awsId,
      device_ids: deviceIds,
      channels: channel,
    };
    handleSendData(data, componentType);
  };

  const handleOpen = () => setIsSelectChannel(true);
  const handleClose = () => setIsSelectChannel(false);

  return (
    <Container>
      <TitleContainer>{t('device-update-title')}</TitleContainer>
      {gateway && (
        <RowContainer container>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-gateway-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-gateway-label')}
              hasBorder
              value={gatewayName}
              name="name"
              OnChange={handleChange}
              error={!!errors?.deviceEUI}
              errorMessage={errors?.deviceEUI}
            />
          </FieldContainer>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>
              {t('device-add-building-label')}
            </FieldDescription>
            <SelectInput
              values={buildings}
              value={building}
              OnChange={handleChangeBuilding}
            />
          </FieldContainer>
        </RowContainer>
      )}
      {!gateway && (
        <RowContainer container>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-eui-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-eui-label')}
              hasBorder
              value={deviceEUI}
              name="deviceEUI"
              OnChange={handleChange}
              error={!!errors?.deviceEUI}
              errorMessage={errors?.deviceEUI}
            />
          </FieldContainer>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-key-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-key-label')}
              hasBorder
              value={applicationKey}
              name="applicationKey"
              OnChange={handleChange}
              error={!!errors?.applicationKey}
              errorMessage={errors?.applicationKey}
            />
          </FieldContainer>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-app-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-app-label')}
              hasBorder
              value={deviceApplicationEUI}
              name="deviceApplicationEUI"
              OnChange={handleChange}
              error={!!errors?.deviceApplicationEUI}
              errorMessage={errors?.deviceApplicationEUI}
            />
            {controller && (
              <div
                style={{ display: 'flex', justifyContent: 'center', gap: 8 }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{t('device-update-channel-use')} A</span>
                  <Checkbox
                    checked={channelA}
                    sx={{
                      color: 'grey',
                      '&.Mui-checked': {
                        color: '#FF0018',
                      },
                    }}
                    onChange={() => setChannelA((prev: boolean) => !prev)}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{t('device-update-channel-use')} B</span>
                  <Checkbox
                    checked={channelB}
                    sx={{
                      color: 'grey',
                      '&.Mui-checked': {
                        color: '#FF0018',
                      },
                    }}
                    onChange={() => setChannelB((prev: boolean) => !prev)}
                  />
                </div>
              </div>
            )}
          </FieldContainer>
          {sensor && (
            <FieldContainer item xs={12} sm={12} md lg>
              <FieldDescription>{t('device-add-controller')}</FieldDescription>
              <SelectSearch
                multiple
                options={controllersIds}
                value={devices}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                OnChange={(e, newValue) => setDevices(newValue)}
                displayOption={(props, option) => {
                  return (
                    <li {...props}>
                      {option.etage} {option.area} {option.subArea} {option.dev}
                    </li>
                  );
                }}
              />

              {devices.length > 1 && (
                <ActionButton
                  buttonLabel={t('device-update-channel-button')}
                  color={'primary'}
                  OnClick={handleOpen}
                  style={{
                    borderRadius: 6,
                    marginTop: '8px',
                  }}
                />
              )}
              {devices.length > 0 && devices.length < 2 && (
                <div
                  style={{ display: 'flex', justifyContent: 'center', gap: 8 }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{t('device-update-channel-use')} A</span>
                    <Checkbox
                      checked={channelA}
                      sx={{
                        color: 'grey',
                        '&.Mui-checked': {
                          color: '#FF0018',
                        },
                      }}
                      onChange={() => setChannelA((prev: boolean) => !prev)}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{t('device-update-channel-use')} B</span>
                    <Checkbox
                      checked={channelB}
                      sx={{
                        color: 'grey',
                        '&.Mui-checked': {
                          color: '#FF0018',
                        },
                      }}
                      onChange={() => setChannelB((prev: boolean) => !prev)}
                    />
                  </div>
                </div>
              )}
            </FieldContainer>
          )}
        </RowContainer>
      )}

      {!gateway && (
        <RowContainer container>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-model-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-model-placeholder')}
              hasBorder
              value={model}
              name="model"
              OnChange={handleChange}
              error={!!errors?.model}
              errorMessage={errors?.model}
            />
          </FieldContainer>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-serial-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-serial-label')}
              hasBorder
              value={serialNumber}
              name="serialNumber"
              OnChange={handleChange}
              error={!!errors?.serialNumber}
              errorMessage={errors?.serialNumber}
            />
          </FieldContainer>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>{t('device-add-serial-label')}</FieldDescription>
            <UnifiedInput
              placeholder={t('device-add-serial-label')}
              hasBorder
              value={assetNumber}
              name="assetNumber"
              OnChange={handleChange}
              error={!!errors?.assetNumber}
              errorMessage={errors?.assetNumber}
            />
          </FieldContainer>
        </RowContainer>
      )}
      <RowContainer container>
        {!gateway && (
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>
              {t('device-add-building-label')}
            </FieldDescription>
            <SelectInput
              values={buildings}
              value={building}
              OnChange={handleChangeBuilding}
            />
          </FieldContainer>
        )}
        <FieldContainer item xs={12} sm={12} md lg>
          <FieldDescription>{t('device-add-floor-label')}</FieldDescription>
          <UnifiedInput
            placeholder={t('device-add-floor-label')}
            hasBorder
            value={floor}
            name="floor"
            OnChange={handleChangeFloor}
            error={!!errors?.floor}
            errorMessage={errors?.floor}
          />
        </FieldContainer>
        <FieldContainer item xs={12} sm={12} md lg>
          <FieldDescription>
            {t('device-add-floor-area-label')}
          </FieldDescription>
          <UnifiedInput
            placeholder={t('device-add-floor-area-label')}
            hasBorder
            value={floorArea}
            name="floorArea"
            OnChange={handleChange}
            error={!!errors?.floorArea}
            errorMessage={errors?.floorArea}
          />
        </FieldContainer>
        <FieldContainer item xs={12} sm={12} md lg>
          <FieldDescription>{t('device-add-floor-sub-label')}</FieldDescription>
          <UnifiedInput
            placeholder={t('device-add-floor-sub-label')}
            hasBorder
            value={floorSubArea}
            name="floorSubArea"
            OnChange={handleChange}
            error={!!errors?.floorSubArea}
            errorMessage={errors?.floorSubArea}
          />
        </FieldContainer>
      </RowContainer>
      {!gateway && (
        <RowContainer>
          <FieldContainer item xs={12} sm={12} md lg>
            <FieldDescription>AWS ID</FieldDescription>
            <UnifiedInput
              placeholder="AWS ID"
              hasBorder
              value={awsId}
              name="awsId"
              OnChange={handleChange}
              error={!!errors?.awsId}
              errorMessage={errors?.awsId}
            />
          </FieldContainer>
        </RowContainer>
      )}
      <div
        style={{
          padding: '8px 16px',
          background: '#FF0018',
          borderRadius: 6,
          display: 'flex',
          alignSelf: 'baseline',
        }}
      >
        <div
          style={{
            color: 'white',
            fontSize: 14,
            fontWeight: '600',
            lineHeight: '20px',
            cursor: 'pointer',
          }}
          onClick={sendData}
        >
          {t('button-update')}
        </div>
      </div>
      <ChannelModal
        open={isSelectChannel}
        handleClose={handleClose}
        devices={devices}
        setChannels={setChannels}
      />
    </Container>
  );
};

const Container = styled('div')`
  padding: 16px 20px;
  border-radius: 10px;
  border: 0.5px ${ColorType.darkGrey} solid;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TitleContainer = styled('div')`
  color: ${ColorType.darkGrey};
  font-weight: 600;
  letter-spacing: 0.02px;
  font-family: Inter;
`;

const RowContainer = styled(Grid)`
  gap: 12px;
  display: flex;
`;

const FieldContainer = styled(Grid)`
  flex-direction: column;
  gap: 6;
  display: flex;
`;

const FieldDescription = styled('div')`
  color: ${ColorType.darkGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;
  font-family: Inter;
`;

export default DeviceUpdateCard;
