import { HeadCell, MenuItems, RoleOption } from './types';

import {
  RectangleGroup,
  UserGroup,
  BuildingOffice,
  Bolt,
  CalendarDays,
  Company,
} from '../components/svg/String';

export const conciergeRoles: RoleOption[] = [
  { value: 'Choose a role', label: 'Choose a role', placeholder: true },
];

export const buildingManagerRoles: RoleOption[] = [
  ...conciergeRoles,
  { value: 'concierge', label: 'Concierge' },
];

export const managerRoles: RoleOption[] = [
  ...buildingManagerRoles,
  { value: 'building-manager', label: 'Building Manager' },
];

export const superAdminRoles: RoleOption[] = [
  { value: 'Choose a role', label: 'Choose a role', placeholder: true },
  { value: 'manager', label: 'Admin' },
];

export const extraAdminRoles: RoleOption[] = [
  {
    value: 'Choose a role',
    label: 'Choose a role',
    labelFr: 'Choisir un role',
    placeholder: true,
  },
  { value: 'manager', label: 'Admin', labelFr: 'Admin' },
  {
    value: 'building-manager',
    label: 'Building Manager',
    labelFr: "Gestionnaire de l'immeuble",
  },
  { value: 'concierge', label: 'Concierge', labelFr: 'Concierge' },
];

//Menu
export const buildingManagerMenu = [
  {
    index: 2,
    name: 'Users',
    nameFr: 'Utilisateurs',
    link: '/users',
    icon: 'UserGroup',
  },
  {
    index: 5,
    name: 'Building',
    nameFr: 'Batiment',
    link: '/building-management',
    icon: 'BuildingOffice',
  },
  {
    index: 6,
    name: 'Devices',
    nameFr: 'Dispositifs',
    link: '/devices',
    icon: 'Bolt',
  },
  {
    index: 7,
    name: 'Schedulers',
    nameFr: 'Planificateurs',
    link: '/schedulers',
    icon: 'CalendarDays',
  },
];
export const managerMenu = [
  {
    index: 2,
    name: 'Users',
    nameFr: 'Utilisateurs',
    link: '/users',
    icon: 'UserGroup',
  },
  {
    index: 4,
    name: 'Company',
    nameFr: 'Entreprise',
    link: '/company-management',
    icon: 'BuildingOffice',
  },
  {
    index: 5,
    name: 'Building',
    nameFr: 'Batiment',
    link: '/building-management',
    icon: 'BuildingOffice',
  },
  {
    index: 6,
    name: 'Devices',
    nameFr: 'Dispositifs',
    link: '/devices',
    icon: 'Bolt',
  },
  {
    index: 7,
    name: 'Schedulers',
    nameFr: 'Planificateurs',
    link: '/schedulers',
    icon: 'CalendarDays',
  },
];
export const adminMenu = [
  {
    index: 2,
    name: 'Users',
    nameFr: 'Utilisateurs',
    link: '/users',
    icon: 'UserGroup',
  },
  {
    index: 4,
    name: 'Company',
    nameFr: 'Entreprise',
    link: '/company-management',
    icon: 'Company',
  },
];

export const superAdminMenu = [...adminMenu];

export const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Building Name',
    labelFr: 'Nom du batiment',
    hasFilter: false,
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    labelFr: 'Adresse',
    hasFilter: false,
  },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

export const gatewayHeadCells: HeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'ID',
  //   hasFilter: true,
  // },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Gateway Name',
    labelFr: 'Nom de la passerelle',
    hasFilter: true,
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
    labelFr: 'Batiment',
  },
  {
    id: 'floor',
    numeric: false,
    disablePadding: false,
    label: 'Floor',
    labelFr: 'Etage',
  },
  {
    id: 'area',
    numeric: false,
    disablePadding: false,
    label: 'Area',
    labelFr: 'Zone',
  },
  {
    id: 'subArea',
    numeric: false,
    disablePadding: false,
    label: 'Sub Area',
    labelFr: 'Sous-zone',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    labelFr: 'Statut',
  },
];

export const controllerHeadCells: HeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'ID',
  //   hasFilter: true,
  // },
  {
    id: 'eui',
    numeric: false,
    disablePadding: true,
    label: 'Controller',
    labelFr: 'Controlleur',
    hasFilter: true,
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: true,
    label: 'Model',
    labelFr: 'Modele',
    hasFilter: true,
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
    labelFr: 'Batiment',
  },
  {
    id: 'floor',
    numeric: false,
    disablePadding: false,
    label: 'Floor',
    labelFr: 'Etage',
  },
  {
    id: 'area',
    numeric: false,
    disablePadding: false,
    label: 'Area',
    labelFr: 'Zone',
  },
  {
    id: 'subArea',
    numeric: false,
    disablePadding: false,
    label: 'Sub Area',
    labelFr: 'Sous-zone',
  },
  {
    id: 'statusA',
    numeric: true,
    disablePadding: false,
    label: 'Status A',
    labelFr: 'Statut A',
  },
  {
    id: 'buttonA',
    numeric: true,
    disablePadding: false,
    label: 'Action A',
    labelFr: 'Action A',
  },
  {
    id: 'statusB',
    numeric: true,
    disablePadding: false,
    label: 'Status B',
    labelFr: 'Statut B',
  },
  {
    id: 'buttonB',
    numeric: true,
    disablePadding: false,
    label: 'Action B',
    labelFr: 'Action B',
  },
];

export const sensorHeadCellsClosed: HeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'ID',
  //   hasFilter: true,
  // },
  {
    id: 'eui',
    numeric: false,
    disablePadding: true,
    label: 'Sensor',
    labelFr: 'Capteur',
    hasFilter: true,
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: true,
    label: 'Model',
    labelFr: 'Modele',
    hasFilter: true,
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
    labelFr: 'Batiment',
  },
  {
    id: 'floor',
    numeric: false,
    disablePadding: false,
    label: 'Floor',
    labelFr: 'Etage',
  },
  {
    id: 'area',
    numeric: false,
    disablePadding: false,
    label: 'Area',
    labelFr: 'Zone',
  },
  {
    id: 'subArea',
    numeric: false,
    disablePadding: false,
    label: 'Sub Area',
    labelFr: 'Sous-zone',
  },
  {
    id: 'statusA',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    labelFr: 'Statut',
  },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: 'Battery',
    labelFr: 'Batterie',
  },
  {
    id: 'statusB',
    numeric: true,
    disablePadding: false,
    label: '',
    labelFr: '',
  },
  {
    id: 'buttonB',
    numeric: true,
    disablePadding: false,
    label: '',
    labelFr: '',
  },
];

export const sensorHeadCellsOpen: HeadCell[] = [
  // {
  //   id: 'id',
  //   numeric: false,
  //   disablePadding: true,
  //   label: 'ID',
  //   hasFilter: true,
  // },
  {
    id: 'eui',
    numeric: false,
    disablePadding: true,
    label: 'Sensor',
    labelFr: 'Capteur',
    hasFilter: true,
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: true,
    label: 'Model',
    labelFr: 'Modele',
    hasFilter: true,
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
    labelFr: 'Batiment',
  },
  {
    id: 'floor',
    numeric: false,
    disablePadding: false,
    label: 'Floor',
    labelFr: 'Etage',
  },
  {
    id: 'area',
    numeric: false,
    disablePadding: false,
    label: 'Area',
    labelFr: 'Zone',
  },
  {
    id: 'subArea',
    numeric: false,
    disablePadding: false,
    label: 'Sub Area',
    labelFr: 'Sous-zone',
  },
  {
    id: 'statusA',
    numeric: true,
    disablePadding: false,
    label: 'Status A',
    labelFr: 'Statut A',
  },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: 'Action A',
    labelFr: 'Action A',
  },
  {
    id: 'statusB',
    numeric: true,
    disablePadding: false,
    label: 'Status B',
    labelFr: 'Statut B',
  },
  {
    id: 'buttonB',
    numeric: true,
    disablePadding: false,
    label: 'Action B',
    labelFr: 'Action B',
  },
];

export const messagesHeadCells: HeadCell[] = [
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: true,
    label: 'Timestamp',
    labelFr: 'Horodatage',
    hasFilter: true,
  },
  {
    id: 'devEui',
    numeric: false,
    disablePadding: true,
    label: 'devEUI',
    hasFilter: true,
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: true,
    label: 'Building',
    labelFr: 'Batiment',
    hasFilter: false,
  },
  {
    id: 'deviceModelDescription',
    numeric: false,
    disablePadding: false,
    label: 'deviceModalDescription',
    hasFilter: true,
  },
  {
    id: 'datadecoded',
    numeric: false,
    disablePadding: false,
    label: 'dataDecoded',
  },
];

export const scheduleHeadCells: HeadCell[] = [
  {
    id: 'eui',
    numeric: false,
    disablePadding: false,
    label: 'Controller',
    labelFr: 'Controleur',
    hasFilter: true,
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
    labelFr: 'Batiment',
  },
  {
    id: 'floor',
    numeric: false,
    disablePadding: false,
    label: 'Floor',
    labelFr: 'Etage',
  },
  {
    id: 'area',
    numeric: false,
    disablePadding: false,
    label: 'Area',
    labelFr: 'Zone',
  },
  {
    id: 'subArea',
    numeric: false,
    disablePadding: false,
    label: 'Sub Area',
    labelFr: 'Sous-zone',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    labelFr: 'Statut',
  },
];

export const userHeadCells: HeadCell[] = [
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'User',
    labelFr: 'Utilisateur',
  },
  {
    id: 'info',
    numeric: false,
    disablePadding: false,
    label: 'Company',
    labelFr: 'Entreprise',
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
    labelFr: 'Batiment',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    labelFr: 'Statut',
  },
  {
    id: 'role',
    numeric: true,
    disablePadding: false,
    label: 'Role',
    hasFilter: true,
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
  },
];

export const iconMappings = [
  { name: 'RectangleGroup', icon: RectangleGroup },
  { name: 'UserGroup', icon: UserGroup },
  { name: 'Bolt', icon: Bolt },
  { name: 'CalendarDays', icon: CalendarDays },
  { name: 'BuildingOffice', icon: BuildingOffice },
  { name: 'Company', icon: Company },
];

export const WeekDays = [
  {
    id: 1,
    name: 'Mon',
    nameFr: 'Lun',
    isSelected: false,
  },
  {
    id: 2,
    name: 'Tue',
    nameFr: 'Mar',
    isSelected: false,
  },
  {
    id: 3,
    name: 'Wed',
    nameFr: 'Mer',
    isSelected: false,
  },
  {
    id: 4,
    name: 'Thu',
    nameFr: 'Jeu',
    isSelected: false,
  },
  {
    id: 5,
    name: 'Fri',
    nameFr: 'Ven',
    isSelected: false,
  },
  {
    id: 6,
    name: 'Sat',
    nameFr: 'Sam',
    isSelected: false,
  },
  {
    id: 0,
    name: 'Sun',
    nameFr: 'Dim',
    isSelected: false,
  },
];

export const Actions = [
  { value: 'open_valve', label: 'Ouvert' },
  { value: 'close_valve', label: 'Fermé' },
];
