/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { Order } from '../../const/types';
import { userHeadCells } from '../../const/arrays';
import { getComparator, setAxiosHeaders, stableSort } from '../../utils/helper';
import PaginationTable from './PaginationTable';
import { useNavigate } from 'react-router';
import { Status } from '../Badge';
import ColorType from '../Theme/ColorType';
import { PencilSquare, UserOutlined, Envelope } from '../svg';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import { Avatar } from '@mui/material';
import { usersSelector } from '../../redux/selectors/users';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { isAdminSelector, isManagerSelector } from '../../redux/selectors/user';
import PopupConfirmDelete from '../Modal/PopupConfirmDelete';
import { changeUserPin } from '../../redux/actions/usersAction';
import { UsersDTO } from '../../redux/types/users.dto';
import { Text } from '../Text';
import { ReinviteUser } from '../../redux/actions/userAction';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';
import { buildingIdSelector } from '../../redux/selectors/building';
import { companyIdSelector } from '../../redux/selectors/company';

type colorsProp = {
  bgColor?: ColorType;
  circleBg?: ColorType;
};

const displayStatus = (status: string) => {
  const colors: colorsProp = {};
  switch (status) {
    case 'Pending':
    case 'En cours':
      colors.bgColor = ColorType.lightOrange;
      colors.circleBg = ColorType.softOrange;
      return colors;
    case 'Active':
    case 'Actif':
      colors.bgColor = ColorType.lightGreen;
      colors.circleBg = ColorType.softGreen;
      return colors;
    case 'default':
    default:
      colors.bgColor = ColorType.lightGrey;
      colors.circleBg = ColorType.softGrey;
      return colors;
  }
};

const UserTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const users = useAppSelector(usersSelector);
  const isManager = useAppSelector(isManagerSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const companyId = useAppSelector(companyIdSelector);
  const buildingId = useAppSelector(buildingIdSelector);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof UsersDTO>('name');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [openReinvite, setOpenReinvite] = useState(false);
  const [userId, setUserId] = useState(0);
  useEffect(() => {
    // Set the initial headers
    setAxiosHeaders();

    // Update headers when language changes
    const handleLanguageChange = () => {
      setAxiosHeaders();
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenReinvite = (id: number) => {
    setUserId(id);
    setOpenReinvite(true);
  };

  const handleCloseReinvite = () => setOpenReinvite(false);

  useEffect(() => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / rowsPerPage); i += 1) {
      pageNumbers.push(i);
    }
    setTotalPages(pageNumbers.length);
  }, [rowsPerPage, users]);

  useEffect(() => {
    setPage(0);
  }, [buildingId, companyId]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UsersDTO
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(users, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, users]
  );

  const handleClick = (row: any) => {
    navigate(`user-details/${row.id}`, { state: { user: row } });
  };

  const handleOpenChangePin = (id: number) => {
    setUserId(id);
    handleOpen();
  };

  const handleChangePin = () => {
    dispatch(changeUserPin({ userId, handleClose }));
  };

  const handleReinviteUser = () => {
    dispatch(ReinviteUser(userId));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <EnhancedTableToolbar title="Users" description="Manage Users" />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              headCells={userHeadCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const circleBg = displayStatus(
                  row?.status?.toString()
                )?.circleBg;
                const bgColor = displayStatus(row?.status?.toString())?.bgColor;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                    sx={{
                      // cursor: isItemSelected ? 'auto' : 'pointer',
                      borderBottom: '1px solid #D1D9E2',
                    }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: '16px 0px 16px 24px',
                      }}
                    >
                      <RowContainer>
                        <Avatar
                          alt="Remy Sharp"
                          sx={{
                            width: 40,
                            height: 40,
                            marginLeft: '-7px',
                            backgroundColor: ColorType.darkGrey,
                          }}
                        >
                          <UserOutlined stroke={ColorType.background} />
                        </Avatar>
                        <ColumnContainer>
                          <NameText>{row.name}</NameText>
                          <EmailText>{row.email}</EmailText>
                        </ColumnContainer>
                      </RowContainer>
                    </TableCell>
                    <TableCell align="left">
                      <StyledDiv>
                        {/* @ts-ignore */}
                        {isAdmin && <Text>{row?.company?.name}</Text>}
                        {/* <Text>{row.job}</Text>
                        <Text>{row.mission}</Text> */}
                      </StyledDiv>
                    </TableCell>
                    <TableCell align="left">
                      <StyledDiv>
                        {/* @ts-ignore */}
                        {row.buildings.map((building) => (
                          <Text>{building.name}</Text>
                        ))}
                      </StyledDiv>
                    </TableCell>
                    <TableCell align="right">
                      <Status
                        text={row?.status?.toString()}
                        isShowCircle
                        circleColor={circleBg}
                        bgColor={bgColor}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {/* @ts-ignore */}
                      <Text>{row.role === 'manager' ? 'Admin' : row.role}</Text>
                    </TableCell>
                    <TableCell align="right">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        {(row.status === 'Expired' ||
                          row.status === 'Expiré') && (
                          <Envelope
                            height={17}
                            width={17}
                            style={{ cursor: 'pointer', color: '#0c5399a6' }}
                            onClick={() => handleOpenReinvite(Number(row.id))}
                          />
                        )}
                        {(isManager || isAdmin) && (
                          <PinOutlinedIcon
                            height={17}
                            width={17}
                            style={{ cursor: 'pointer', color: '#0c5399a6' }}
                            onClick={() => handleOpenChangePin(Number(row.id))}
                          />
                        )}
                        <PencilSquare
                          height={17}
                          width={17}
                          color="#0C5399"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleClick(row)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationTable
          page={page}
          totalPages={totalPages}
          setPage={setPage}
        />
      </Paper>
      <PopupConfirmDelete
        open={open}
        handleClick={handleChangePin}
        handleClose={handleClose}
        message={t('users-update-title')}
        description={t('users-update-description')}
        label={t('users-update-label')}
        pass="UPDATE"
      />
      <PopupConfirmDelete
        open={openReinvite}
        handleClick={handleReinviteUser}
        handleClose={handleCloseReinvite}
        message={t('users-reinvite-title')}
        description={t('users-reinvite-description')}
        label={t('users-reinvite-label')}
        pass="REINVITE"
      />
    </Box>
  );
};

const RowContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const ColumnContainer = styled('div')`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

const NameText = styled('span')`
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  color: ${ColorType.darkGrey};
`;

const EmailText = styled('span')`
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  color: ${ColorType.black};
`;

// const Text = styled('span')`
//   font-size: 14px;
//   font-weight: 300;
//   font-family: Inter;
//   color: ${ColorType.darkGrey};
// `;

export default UserTable;
